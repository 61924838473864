/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect } from 'react';

import './App.css';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery';

// COMPONENTS
import Navbar from './Website/Components/Navbar/Navbar';
import Footer from './Website/Components/Footer/SeaBoard_Footer';
import UpButton from './Website/Pages/Home/Components/Up_Button/Up_Button';

// PAGES
const Home = lazy(() => import('./Website/Pages/Home/Home'));
const AboutUs = lazy(() => import('./Website/Pages/About_Us/About_Us'));
const SeaboardCompanies = lazy(() => import('./Website/Pages/Seaboard_Companies/Seaboard_Companies'));
const CompanyDetails = lazy(() => import('./Website/Pages/Company_Details/Company_Details'));
const CareerForm = lazy(() => import('./Website/Pages/CareerForm/CareerForm'));
const Contact = lazy(() => import('./Website/Pages/Contact/Contact'));
const Seaboard_History2 = lazy( () => import('./Website/Pages/Seaboard_History2/Seaboard_History2') );
const SeaBoardFamily = lazy(() => import('./Website/Pages/SeaboardFamily/SeaBoardFamily'));
const Values = lazy(() => import('./Website/Pages/Values/Values'));
const Singleblog = lazy(() => import('./Website/Pages/Blogs/SingleBlog/SingleBlog'));
const Gallery = lazy(() => import('./Website/Pages/Gallery/Gallery'));



const Blogs = lazy(() => import('./Website/Pages/Blogs/Blogs'));
// const Awards = lazy( () => import('./Website/Pages/Awards/Awards') );

// DASHBOARD
// const Dashboard = lazy( () => import('./Dashboard/Dashboard') );
// const ContainerInquery = lazy( () => import('./Dashboard/Container_Inquery/Container_Inquery') );

const App = () => {

  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => {
      $(document).ready(function () {

        $('.Seaboard_SideBar .SideBar_Div a').on('click', function () {

          $('.Seaboard_SideBar .SideBar_Div .la-times').trigger('click');

        })

        if ($("html").scrollTop() === 0) {
          $(".Up_Button").hide();
        }

        document.addEventListener('scroll', () => {

          if ($("html").scrollTop() === 0) {
            $(".Up_Button").hide();
          } else {
            $(".Up_Button").show();
          }

        })


        $(".Seaboard_SideBar a").on('click', function () {
          $("html, body").animate({ scrollTop: 0 }, 300);
          return true;
        });
        $(".SeaBoard_Footer a").on('click', function () {
          $("html, body").animate({ scrollTop: 0 }, 300);
          return true;
        });
        $(".Up_Button").on('click', function () {
          $("html, body").animate({ scrollTop: 0 }, 300);
          return true;
        });
      });
    }, [history.location.pathname]
  )
  
  useEffect(() => {
    $('.loading-screen').removeClass('fading');
    setTimeout(() => {
      $('.loading-screen').addClass('fading');
    }, 500);
  }, [location])

  return (
    <>
      <div className="loading-screen fading"><div>Seaboard</div></div>
      {
        window.location.href.includes('dashboard')
          ? null
          :
          <>
            <UpButton />
            <Navbar />
          </>
      }

      <Switch>

        <Route
          exact
          path="/"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Home /></Suspense>
          }
        />
        <Route
          exact
          path="/about_us"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><AboutUs /></Suspense>
          }
        />
        <Route
          exact
          path="/Seaboard_Companies"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><SeaboardCompanies /></Suspense>
          }
        />
        <Route
          exact
          path="/Seaboard_Companies/:company"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><CompanyDetails /></Suspense>
          }
        />
        <Route
          exact
          path="/careers"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><CareerForm /></Suspense>
          }
        />
        <Route
          exact
          path="/contact"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Contact /></Suspense>
          }
        />
        <Route
          exact
          path="/seaboard_family"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><SeaBoardFamily /></Suspense>
          }
        />
        <Route
          exact
          path="/values"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Values /></Suspense>
          }
        />
        <Route
          exact
          path="/blogs"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Blogs /></Suspense>
          }
        />

        <Route
          exact
          path="/blogs/single-blog"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Singleblog /></Suspense>
          }
        />

        <Route
          exact
          path="/ourhistory"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Seaboard_History2 /></Suspense>
          }
        />
        <Route
          exact
          path="/gallery"
          render={
            () => <Suspense fallback={<div>Loading....</div>}><Gallery /></Suspense>
          }
        />
        {/*
        <Route
          exact
          path="/ourhistory"
          render={
            () =>  <Suspense fallback={ <div>Loading....</div> }><Seaboard_History2 /></Suspense>
          }
        />


        <Route
          exact
          path="/awards"
          render={
            () =>  <Suspense fallback={ <div>Loading....</div> }><Awards /></Suspense>
          }
        />

        <Route
          exact
          path="/dashboard"
          render={
            () =>  <Suspense fallback={ <div>Loading....</div> }><Dashboard /></Suspense>
          }
        />
        <Route
          exact
          path="/dashboard/container_inquery"
          render={
            () =>  <Suspense fallback={ <div>Loading....</div> }><ContainerInquery /></Suspense>
          }
        /> */}

      </Switch>

      {
        window.location.href.includes('dashboard')
          ? null
          :
          <>
            
            <Suspense fallback={<div>Loading....</div>}><Footer /></Suspense>
          </>
      }

    </>
  )

}

export default App;
